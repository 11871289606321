import Swal from "sweetalert2";

class NotificationPersonsList {
    constructor(settings) {
        this.list_notification_person_url = settings.list_persons;
        this.csrf = settings.csrf;
        this.init();
        this.bindEvents();
    }

    init() {
        this.cacheDOM();
        this.initDatatable();
    }

    cacheDOM() {
        this.$notification_person_table = $("#notification_person_table");
    }

    bindEvents() {
        $("body").on("click", ".delete-record", this.deleteUser.bind(this));
        $("body").on("click", ".view-details", this.viewLocations.bind(this));
    }

    initDatatable() {
        const SELF = this;

        this.$table = SELF.$notification_person_table.DataTable({
            ajax: {
                url: SELF.list_notification_person_url,
                data: function (data) {
                    data._method = "PATCH";
                    $(".filters input, .filters select").each(function () {
                        let name = $(this).attr("name"),
                            value =
                                $(this).attr("type") == "checkbox"
                                    ? $(this).is(":checked")
                                        ? $(this).val()
                                        : 0
                                    : $(this).val();

                        data[name] = value;
                    });
                },
            },
            language: {
                search: "",
                searchPlaceholder: "search",
            },
            columns: [
                {
                    data: "sn",
                },
                {
                    data: "first_name",
                },
                {
                    data: "last_name",
                },
                {
                    data: "phone",
                },
                {
                    data: "email",
                },
                {
                    data: "action",
                },
            ],
            columnDefs: [
                {
                    visible: false,
                    targets: [0],
                },
                {
                    orderable: false,
                    targets: [0, 5],
                },
            ],
            destroy: true,
            orderable: true,
            lengthMenu: [10, 20, 50, 100, 200, 500],
            orderCellsTop: true,
            paging: true,
            processing: true,
            searching: true,
            serverSide: true,
            stateSave: false,
            stripeClasses: [],
            sorting: [[2, "desc"]],
        });
    }

    deleteUser(e) {
        e.preventDefault();
        const _SELF = this,
            TARGET = $(e.currentTarget);

        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    url: TARGET.data("url"),
                    type: "DELETE",
                    dataType: "json",
                    cache: false,
                    processData: false,
                    success: function (response, textStatus, xhr) {
                        _SELF.$table.draw();

                        toastr.success(
                            "Person has been Successfully deleted",
                            "",
                            {
                                timeOut: 30000,
                                extendedTImeout: "1000",
                            }
                        );
                    },
                    error: function (data) {
                        let responseJSON = data.responseJSON;

                        toastr.error(responseJSON.message, "", {
                            timeOut: 30000,
                            extendedTImeout: "1000",
                        });
                    },
                });
            }

            Swal.close();
        });
    }

    viewLocations(e) {
        e.preventDefault();
        const _SELF = this,
            TARGET = $(e.currentTarget);

        let source = document.getElementById("template").innerHTML,
            template = Handlebars.compile(source),
            result = template({
                locations: TARGET.data("locations"),
            });

        $("#modal .modal-body").html(result);
        $("#modal").modal("show");
    }
}

window.NotificationPersonsList = NotificationPersonsList;
