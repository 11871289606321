window._ = require("lodash");

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");
    window.toastr = require("toastr/toastr.js");

    require("bootstrap");

    require("jquery-validation");
    require("datatables.net-bs4");
    require("datatables.net-buttons-bs4");
    window.Handlebars = require("handlebars/dist/handlebars.min.js");
    require("./helpers");
    require("select2");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

$.validator.setDefaults({
    errorElement: "span",
    errorPlacement: function (error, element) {
        error.addClass("error");
        element.closest(".form-group").append(error);
    },
    highlight: function (element, errorClass, validClass) {
        $(element).addClass("is-invalid");
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass("is-invalid");
    },
});

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

require("./users/index");
require("./users/add");
require("./users/edit");

require("./locations/index");
require("./locations/add");
require("./locations/edit");

require("./persons/index");
require("./persons/add");
require("./persons/edit");

require("./templates/index");

require("./admin/tenants/index");
require("./admin/tenants/add");
require("./admin/tenants/edit");

require("./bcc/index");
