class AddTenants {
    constructor(settings) {
        this.counter = 0;
        this.init();
    }

    init() {
        this.cacheDOM();
        this.submitForm();
        this.bindEvents();
    }

    cacheDOM() {
        this.$tenant_form = $("#tenant_form");
        this.$tenant = $(".tenant");
        this.$add_more_domains_btn = $("#add_more_domains");
    }

    bindEvents() {
        $("body").on(
            "click",
            "#add_more_domains",
            this.addToForm.bind(this)
        );

        $("body").on(
            "click",
            ".remove_domains",
            this.removePersonsFromForm.bind(this)
        );
    }

    submitForm() {
        const FORM = this.$tenant_form;
        var validator = FORM.validate({
            submitHandler: function (form) {

                $.ajax({
                    url: FORM.attr("action"),
                    type: "POST",
                    contentType: false,
                    data: new FormData(FORM[0]),
                    processData: false,
                    beforeSend: function () {
                        FORM.find("button[type='submit']")
                            .html(
                                `<span style="margin-bottom: 5px" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Submitting....`
                            )
                            .prop("disabled", true);
                    },
                    complete: function () {
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);
                    },
                    success: function (response) {
                        toastr.success(response.message, "", {
                            timeOut: 30000,
                            extendedTImeout: "1000",
                        });

                        FORM[0].reset();

                        setTimeout(function () {
                            location.reload();
                        }, 2000);
                    },
                    error: function (data) {
                        let responseJSON = data.responseJSON;
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);

                        if (data.status == 422) {
                            let err = {};

                            $.each(responseJSON.errors, function (index, v) {
                                if (index == "owner" || index == "sms") {
                                    // err[`${index}[]`] = v;
                                    responseJSON.message = v;
                                } else if (
                                    index.includes(".") &&
                                    (index.includes("apt_type") ||
                                        index.includes("lead_type"))
                                ) {
                                    const arr = index.split(".");
                                    console.log(arr);
                                    err[`${arr[0]}[${arr[1]}][${arr[2]}]`] = v;
                                } else if (
                                    !index.includes(".") &&
                                    !index.includes("tenant")
                                ) {
                                    err[index] = v;
                                } else {
                                    const arr = index.split(".");
                                    err[`${arr[0]}[${arr[1]}]`] = v;
                                }
                            });

                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 10000,
                                    extendedTImeout: "0",
                                }
                            );

                            validator.showErrors(err);

                            $("html, body").animate({
                                scrollTop: $(
                                    validator.errorList[0].element
                                ).offset().top,
                            });
                        } else if (data.status == 423) {
                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 3000,
                                    extendedTImeout: "0",
                                }
                            );
                        }
                    },
                });
                return false;
            },
        });
    }

    addToForm(e) {
        e.preventDefault();

        let source = document.getElementById("template").innerHTML,
            template = Handlebars.compile(source),
            result = template({
                id: this.counter,
                lead_type: ["Web", "ACV", "DM"],
                appointment_type: ["VIRTUAL APPOINTMENT", "WEB/FH/OTHER"],
            });

        $(".team-container").append(result);

        setTimeout(() => {
            $(".animate-hide").addClass("animate");
        }, 100);

        this.counter++;
    }

    removePersonsFromForm(e) {
        e.preventDefault();
        $(e.currentTarget).closest(".animate-hide").removeClass("animate");

        setTimeout(() => {
            $(e.currentTarget).closest(".animate-hide").remove();
        }, 100);
    }
}

window.AddTenants = AddTenants;
