class AddPerson {
    constructor() {
        this.init();
    }

    init() {
        this.cacheDOM();
        this.submitForm();
    }

    cacheDOM() {
        this.$person_form = $("#person_form");
    }

    submitForm() {
        const FORM = this.$person_form;
        var validator = FORM.validate({
            submitHandler: function (form) {
                $.ajax({
                    url: FORM.attr("action"),
                    type: "POST",
                    dataType: "json",
                    data: FORM.serialize(),
                    cache: false,
                    processData: false,
                    beforeSend: function () {
                        FORM.find("button[type='submit']")
                            .html(
                                `<span style="margin-bottom: 5px" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Submitting....`
                            )
                            .prop("disabled", true);
                    },
                    complete: function () {
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);
                    },
                    success: function (response) {
                        toastr.success(response.message, "", {
                            timeOut: 30000,
                            extendedTImeout: "1000",
                        });

                        FORM[0].reset();

                        setTimeout(function () {
                            location.reload();
                        }, 2000);
                    },
                    error: function (data) {
                        let responseJSON = data.responseJSON;
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);

                        if (data.status == 422) {
                            let err = {};

                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 10000,
                                    extendedTImeout: "0",
                                }
                            );

                            $.each(responseJSON.errors, function (i, v) {
                                err[i] = v;
                            });

                            validator.showErrors(err);

                            $("html, body").animate({
                                scrollTop: $(
                                    validator.errorList[0].element
                                ).offset().top,
                            });
                        } else if (data.status == 423) {
                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 3000,
                                    extendedTImeout: "0",
                                }
                            );
                        }
                    },
                });
                return false;
            },
        });
    }
}

window.AddPerson = AddPerson;
