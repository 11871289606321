import ClassicEditor from "../vendor/ckeditor5-custom-build/build/ckeditor";

class Templates {
    constructor(settings) {
        this.list_notification_person_url = settings.list_persons;
        this.csrf = settings.csrf;
        this.init();
        this.bindEvents();
    }

    init() {
        this.cacheDOM();
        this.initEditor();
        this.submitForm();
    }

    cacheDOM() {
        this.$template_form = $("#template_form");
    }

    bindEvents() {}

    initEditor() {
        ClassicEditor.create(document.querySelector("#editor"));
    }

    submitForm() {
        const FORM = this.$template_form;
        var validator = FORM.validate({
            submitHandler: function (form) {
                $.ajax({
                    url: FORM.attr("action"),
                    type: "PUT",
                    dataType: "json",
                    data: FORM.serialize(),
                    cache: false,
                    processData: false,
                    beforeSend: function () {
                        FORM.find("button[type='submit']")
                            .html(
                                `<span style="margin-bottom: 5px" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Submitting....`
                            )
                            .prop("disabled", true);
                    },
                    complete: function () {
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);
                    },
                    success: function (response) {
                        toastr.success(response.message, "", {
                            timeOut: 30000,
                            extendedTImeout: "1000",
                        });

                        FORM[0].reset();

                        setTimeout(function () {
                            location.reload();
                        }, 2000);
                    },
                    error: function (data) {
                        let responseJSON = data.responseJSON;
                        FORM.find("button[type='submit']")
                            .html(`Save`)
                            .prop("disabled", false);

                        if (data.status == 422) {
                            let err = {};

                            $.each(responseJSON.errors, function (index, v) {
                                if (index == "owner" || index == "sms") {
                                    // err[`${index}[]`] = v;
                                    responseJSON.message = v;
                                } else if (
                                    index.includes(".") &&
                                    (index.includes("apt_type") ||
                                        index.includes("lead_type"))
                                ) {
                                    const arr = index.split(".");
                                    console.log(arr);
                                    err[`${arr[0]}[${arr[1]}][${arr[2]}]`] = v;
                                } else if (
                                    !index.includes(".") &&
                                    !index.includes("person")
                                ) {
                                    err[index] = v;
                                } else {
                                    const arr = index.split(".");
                                    err[`${arr[0]}[${arr[1]}]`] = v;
                                }
                            });

                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 10000,
                                    extendedTImeout: "0",
                                }
                            );

                            validator.showErrors(err);

                            $("html, body").animate({
                                scrollTop: $(
                                    validator.errorList[0].element
                                ).offset().top,
                            });
                        } else if (data.status == 423) {
                            toastr.error(
                                responseJSON.message,
                                "Validation Error",
                                {
                                    timeOut: 3000,
                                    extendedTImeout: "0",
                                }
                            );
                        }
                    },
                });
                return false;
            },
        });
    }
}

window.Templates = Templates;
